import baseReactorCoreIcon from "assets/starship-base-reactor-core-icon.svg";
import baseReactorCoreImage from "assets/starship-base-reactor-core.svg";
import baseShieldsIcon from "assets/starship-base-shields-icon.svg";
import baseShieldsImage from "assets/starship-base-shields.svg";
import baseTargetingSystemIcon from "assets/starship-base-targeting-system-icon.svg";
import baseTargetingSystemImage from "assets/starship-base-targeting-system.svg";
import baseThrustersIcon from "assets/starship-base-thrusters-icon.svg";
import baseThrustersImage from "assets/starship-base-thrusters.svg";
import baseWeaponsIcon from "assets/starship-base-weapons-icon.svg";
import baseWeaponsImage from "assets/starship-base-weapons.svg";
import aetherNexusIcon from "assets/upgrade-aether-nexus-icon.svg";
import aetherNexusImage from "assets/upgrade-aether-nexus.svg";
import bishopCoreFourIcon from "assets/upgrade-bishop-core-four-icon.svg";
import bishopCoreFourImage from "assets/upgrade-bishop-core-four.svg";
import bishopCoreOneIcon from "assets/upgrade-bishop-core-one-icon.svg";
import bishopCoreOneImage from "assets/upgrade-bishop-core-one.svg";
import bishopCoreThreeIcon from "assets/upgrade-bishop-core-three-icon.svg";
import bishopCoreThreeImage from "assets/upgrade-bishop-core-three.svg";
import bishopCoreTwoIcon from "assets/upgrade-bishop-core-two-icon.svg";
import bishopCoreTwoImage from "assets/upgrade-bishop-core-two.svg";
import bishopLaserFourIcon from "assets/upgrade-bishop-laser-four-icon.svg";
import bishopLaserFourImage from "assets/upgrade-bishop-laser-four.svg";
import bishopLaserOneIcon from "assets/upgrade-bishop-laser-one-icon.svg";
import bishopLaserOneImage from "assets/upgrade-bishop-laser-one.svg";
import bishopLaserThreeIcon from "assets/upgrade-bishop-laser-three-icon.svg";
import bishopLaserThreeImage from "assets/upgrade-bishop-laser-three.svg";
import bishopLaserTwoIcon from "assets/upgrade-bishop-laser-two-icon.svg";
import bishopLaserTwoImage from "assets/upgrade-bishop-laser-two.svg";
import bishopScopeFourIcon from "assets/upgrade-bishop-scope-four-icon.svg";
import bishopScopeFourImage from "assets/upgrade-bishop-scope-four.svg";
import bishopScopeOneIcon from "assets/upgrade-bishop-scope-one-icon.svg";
import bishopScopeOneImage from "assets/upgrade-bishop-scope-one.svg";
import bishopScopeThreeIcon from "assets/upgrade-bishop-scope-three-icon.svg";
import bishopScopeThreeImage from "assets/upgrade-bishop-scope-three.svg";
import bishopScopeTwoIcon from "assets/upgrade-bishop-scope-two-icon.svg";
import bishopScopeTwoImage from "assets/upgrade-bishop-scope-two.svg";
import bishopShieldFourIcon from "assets/upgrade-bishop-shield-four-icon.svg";
import bishopShieldFourImage from "assets/upgrade-bishop-shield-four.svg";
import bishopShieldOneIcon from "assets/upgrade-bishop-shield-one-icon.svg";
import bishopShieldOneImage from "assets/upgrade-bishop-shield-one.svg";
import bishopShieldThreeIcon from "assets/upgrade-bishop-shield-three-icon.svg";
import bishopShieldThreeImage from "assets/upgrade-bishop-shield-three.svg";
import bishopShieldTwoIcon from "assets/upgrade-bishop-shield-two-icon.svg";
import bishopShieldTwoImage from "assets/upgrade-bishop-shield-two.svg";
import bishopThrusterFourIcon from "assets/upgrade-bishop-thruster-four-icon.svg";
import bishopThrusterFourImage from "assets/upgrade-bishop-thruster-four.svg";
import bishopThrusterOneIcon from "assets/upgrade-bishop-thruster-one-icon.svg";
import bishopThrusterOneImage from "assets/upgrade-bishop-thruster-one.svg";
import bishopThrusterThreeIcon from "assets/upgrade-bishop-thruster-three-icon.svg";
import bishopThrusterThreeImage from "assets/upgrade-bishop-thruster-three.svg";
import bishopThrusterTwoIcon from "assets/upgrade-bishop-thruster-two-icon.svg";
import bishopThrusterTwoImage from "assets/upgrade-bishop-thruster-two.svg";
import bulwarkIcon from "assets/upgrade-bulwark-icon.svg";
import bulwarkImage from "assets/upgrade-bulwark.svg";
import burstLaserIcon from "assets/upgrade-burst-laser-icon.svg";
import burstLaserImage from "assets/upgrade-burst-laser.svg";
import castleCoreImage from "assets/upgrade-castle-core.png";
import darkScopeIcon from "assets/upgrade-dark-scope-icon.svg";
import darkScopeImage from "assets/upgrade-dark-scope.svg";
import energyBarrierIcon from "assets/upgrade-energy-barrier-icon.svg";
import energyBarrierImage from "assets/upgrade-energy-barrier.svg";
import energyBeamIcon from "assets/upgrade-energy-beam-icon.svg";
import energyBeamImage from "assets/upgrade-energy-beam.svg";
import energyScopeIcon from "assets/upgrade-energy-scope-icon.svg";
import energyScopeImage from "assets/upgrade-energy-scope.svg";
import fadeLaserIcon from "assets/upgrade-fade-laser-icon.svg";
import fadeLaserImage from "assets/upgrade-fade-laser.svg";
import faderGridIcon from "assets/upgrade-fader-grid-icon.svg";
import faderGridImage from "assets/upgrade-fader-grid.svg";
import firebrandIcon from "assets/upgrade-firebrand-icon.svg";
import firebrandImage from "assets/upgrade-firebrand.svg";
import flashNexusImage from "assets/upgrade-flash-nexus.png";
import flashRocketIcon from "assets/upgrade-flash-rocket-icon.svg";
import flashRocketImage from "assets/upgrade-flash-rocket.svg";
import forceNexusIcon from "assets/upgrade-force-nexus-icon.svg";
import forceNexusImage from "assets/upgrade-force-nexus.svg";
import forceSanctuaryIcon from "assets/upgrade-force-sanctuary-icon.svg";
import forceSanctuaryImage from "assets/upgrade-force-sanctuary.svg";
import fortressCoreImage from "assets/upgrade-fortress-core.png";
import glanceLaserIcon from "assets/upgrade-glance-laser-icon.svg";
import glanceLaserImage from "assets/upgrade-glance-laser.svg";
import glimmerShieldIcon from "assets/upgrade-glimmer-shield-icon.svg";
import glimmerShieldImage from "assets/upgrade-glimmer-shield.svg";
import glowReactorIcon from "assets/upgrade-glow-reactor-icon.svg";
import glowReactorImage from "assets/upgrade-glow-reactor.svg";
import guardianShieldIcon from "assets/upgrade-guardian-shield-icon.svg";
import guardianShieldImage from "assets/upgrade-guardian-shield.svg";
import heatseekerIcon from "assets/upgrade-heatseeker-icon.svg";
import heatseekerImage from "assets/upgrade-heatseeker.svg";
import hyperReactorIcon from "assets/upgrade-hyper-reactor-icon.svg";
import hyperReactorImage from "assets/upgrade-hyper-reactor.svg";
import hyperburnerIcon from "assets/upgrade-hyperburner-icon.svg";
import hyperburnerImage from "assets/upgrade-hyperburner.svg";
import hypernovaImage from "assets/upgrade-hypernova.png";
import ionBarrierIcon from "assets/upgrade-ion-barrier-icon.svg";
import ionBarrierImage from "assets/upgrade-ion-barrier.svg";
import jetsetterIcon from "assets/upgrade-jetsetter-icon.svg";
import jetsetterImage from "assets/upgrade-jetsetter.svg";
import kineticaIcon from "assets/upgrade-kinetica-icon.svg";
import kineticaImage from "assets/upgrade-kinetica.svg";
import lightLaserIcon from "assets/upgrade-light-laser-icon.svg";
import lightLaserImage from "assets/upgrade-light-laser.svg";
import lightSentinelIcon from "assets/upgrade-light-sentinel-icon.svg";
import lightSentinelImage from "assets/upgrade-light-sentinel.svg";
import lightThrusterIcon from "assets/upgrade-light-thruster-icon.svg";
import lightThrusterImage from "assets/upgrade-light-thruster.svg";
import lockCoreIcon from "assets/upgrade-lock-core-icon.svg";
import lockCoreImage from "assets/upgrade-lock-core.svg";
import lockSightIcon from "assets/upgrade-lock-sight-icon.svg";
import lockSightImage from "assets/upgrade-lock-sight.svg";
import megaReactorIcon from "assets/upgrade-mega-reactor-icon.svg";
import megaReactorImage from "assets/upgrade-mega-reactor.svg";
import moonNexusImage from "assets/upgrade-moon-nexus.png";
import moonShotIcon from "assets/upgrade-moon-shot-icon.svg";
import moonShotImage from "assets/upgrade-moon-shot.svg";
import needleGridIcon from "assets/upgrade-needle-grid-icon.svg";
import needleGridImage from "assets/upgrade-needle-grid.svg";
import nexusRocketIcon from "assets/upgrade-nexus-rocket-icon.svg";
import nexusRocketImage from "assets/upgrade-nexus-rocket.svg";
import novaburnerIcon from "assets/upgrade-novaburner-icon.svg";
import novaburnerImage from "assets/upgrade-novaburner.svg";
import photonSightIcon from "assets/upgrade-photon-sight-icon.svg";
import photonSightImage from "assets/upgrade-photon-sight.svg";
import protectorShieldIcon from "assets/upgrade-protector-shield-icon.svg";
import protectorShieldImage from "assets/upgrade-protector-shield.svg";
import pulseLaserIcon from "assets/upgrade-pulse-laser-icon.svg";
import pulseLaserImage from "assets/upgrade-pulse-laser.svg";
import quantumBladeIcon from "assets/upgrade-quantum-blade-icon.svg";
import quantumBladeImage from "assets/upgrade-quantum-blade.svg";
import quickCoreIcon from "assets/upgrade-quick-core-icon.svg";
import quickCoreImage from "assets/upgrade-quick-core.svg";
import senecaCoreFourIcon from "assets/upgrade-seneca-core-four-icon.svg";
import senecaCoreFourImage from "assets/upgrade-seneca-core-four.svg";
import senecaCoreOneIcon from "assets/upgrade-seneca-core-one-icon.svg";
import senecaCoreOneImage from "assets/upgrade-seneca-core-one.svg";
import senecaCoreThreeIcon from "assets/upgrade-seneca-core-three-icon.svg";
import senecaCoreThreeImage from "assets/upgrade-seneca-core-three.svg";
import senecaCoreTwoIcon from "assets/upgrade-seneca-core-two-icon.svg";
import senecaCoreTwoImage from "assets/upgrade-seneca-core-two.svg";
import sharpCoreIcon from "assets/upgrade-sharp-core-icon.svg";
import sharpCoreImage from "assets/upgrade-sharp-core.svg";
import sharpScopeIcon from "assets/upgrade-sharp-scope-icon.svg";
import sharpScopeImage from "assets/upgrade-sharp-scope.svg";
import shimmerShieldIcon from "assets/upgrade-shimmer-shield-icon.svg";
import shimmerShieldImage from "assets/upgrade-shimmer-shield.svg";
import smallScopeIcon from "assets/upgrade-small-scope-icon.svg";
import smallScopeImage from "assets/upgrade-small-scope.svg";
import sparkBoosterIcon from "assets/upgrade-spark-booster-icon.svg";
import sparkBoosterImage from "assets/upgrade-spark-booster.svg";
import starBeamIcon from "assets/upgrade-star-beam-icon.svg";
import starBeamImage from "assets/upgrade-star-beam.svg";
import starBreakerIcon from "assets/upgrade-star-breaker-icon.svg";
import starBreakerImage from "assets/upgrade-star-breaker.svg";
import starNexusImage from "assets/upgrade-star-nexus.png";
import stellarSightIcon from "assets/upgrade-stellar-sight-icon.svg";
import stellarSightImage from "assets/upgrade-stellar-sight.svg";
import stormCellIcon from "assets/upgrade-storm-cell-icon.svg";
import stormCellImage from "assets/upgrade-storm-cell.svg";
import strikerNexusIcon from "assets/upgrade-striker-nexus-icon.svg";
import strikerNexusImage from "assets/upgrade-striker-nexus.svg";
import supernovaImage from "assets/upgrade-supernova.png";
import swiftNexusImage from "assets/upgrade-swift-nexus.png";
import vaporCoreIcon from "assets/upgrade-vapor-core-icon.svg";
import vaporCoreImage from "assets/upgrade-vapor-core.svg";
import veloNexusIcon from "assets/upgrade-velo-nexus-icon.svg";
import veloNexusImage from "assets/upgrade-velo-nexus.svg";
import visionScopeIcon from "assets/upgrade-vision-scope-icon.svg";
import visionScopeImage from "assets/upgrade-vision-scope.svg";
import visionWallIcon from "assets/upgrade-vision-wall-icon.svg";
import visionWallImage from "assets/upgrade-vision-wall.svg";
import voidCutterIcon from "assets/upgrade-void-cutter-icon.svg";
import voidCutterImage from "assets/upgrade-void-cutter.svg";
import voidBladeImage from "assets/upgrade-void-blade.svg";
import nullBladeImage from "assets/upgrade-null-blade.svg";
import voidDriveIcon from "assets/upgrade-void-drive-icon.svg";
import voidDriveImage from "assets/upgrade-void-drive.svg";
import voidGuardIcon from "assets/upgrade-void-guard-icon.svg";
import voidGuardImage from "assets/upgrade-void-guard.svg";
import voidScopeIcon from "assets/upgrade-void-scope-icon.svg";
import voidScopeImage from "assets/upgrade-void-scope.svg";
import voidWallIcon from "assets/upgrade-void-wall-icon.svg";
import voidWallImage from "assets/upgrade-void-wall.svg";
import voidburnerIcon from "assets/upgrade-voidburner-icon.svg";
import voidburnerImage from "assets/upgrade-voidburner.svg";
import waveCellIcon from "assets/upgrade-wave-cell-icon.svg";
import waveCellImage from "assets/upgrade-wave-cell.svg";
import zephyrCoreIcon from "assets/upgrade-zephyr-core-icon.svg";
import zephyrCoreImage from "assets/upgrade-zephyr-core.svg";
import zeroCoreIcon from "assets/upgrade-zero-core-icon.svg";
import zeroCoreImage from "assets/upgrade-zero-core.svg";
import omegaCoreImage from "assets/upgrade-omega-core.svg";
import apexCoreImage from "assets/upgrade-apex-core.svg";
import nebulaCoreImage from "assets/upgrade-nebula-core.svg";
import eclipseCoreImage from "assets/upgrade-eclipse-core.svg";
import furyReactorImage from "assets/upgrade-fury-reactor.svg";
import alphaReactorImage from "assets/upgrade-alpha-reactor.svg";
import megaCoreImage from "assets/upgrade-mega-core.svg";
import surgeCoreImage from "assets/upgrade-surge-core.svg";
import ravagerCoreImage from "assets/upgrade-ravager-core.svg";
import reaperCoreImage from "assets/upgrade-reaper-core.svg";
import tracerMatrixImage from "assets/upgrade-tracer-matrix.svg";
import sightMatrixImage from "assets/upgrade-sight-matrix.svg";
import burstMatrixImage from "assets/upgrade-burst-matrix.svg";
import dashMatrixImage from "assets/upgrade-dash-matrix.svg";
import aegisCoreImage from "assets/upgrade-aegis-core.svg";
import wardNexusImage from "assets/upgrade-ward-nexus.svg";
import nullShieldImage from "assets/upgrade-null-shield.svg";
import nullBarrierImage from "assets/upgrade-null-barrier.svg";
import plasmaDriveImage from "assets/upgrade-plasma-drive.svg";
import plasmaDriveIcon from "assets/upgrade-plasma-drive-icon.svg";
import novaDriveImage from "assets/upgrade-nova-drive.svg";
import novaDriveIcon from "assets/upgrade-nova-drive-icon.svg";
import phaserSightImage from "assets/upgrade-phaser-sight.svg";
import phaserSightIcon from "assets/upgrade-phaser-sight-icon.svg";
import fluxScopeImage from "assets/upgrade-flux-scope.svg";
import fluxScopeIcon from "assets/upgrade-flux-scope-icon.svg";
import {
  attackAnimation,
  reactorCoreAnimation,
  shieldsAnimation,
  targetingSystemAnimation,
  thrusterAnimation,
} from "components/styles/animations";
import { GradeModel, UpgradeModel } from "types";

const DEFAULT_WEAPONS_SPEED = 300;
const DEFAULT_SHIELDS_SPEED = 1200;
const DEFAULT_THRUSTERS_SPEED = 400;
const DEFAULT_TARGETING_SPEED = 1200;
const DEFAULT_REACTOR_SPEED = 2400;

export const UPGRADES: Record<string, UpgradeModel> = {
  // DEFAULT UPGRADES
  default_weapons: {
    slug: "default_weapons",
    name: "Base Weapon",
    description: "Crappy weapon that came with your starship.",
    image: baseWeaponsImage,
    icon: baseWeaponsIcon,
    animation: attackAnimation,
    animationSpeed: DEFAULT_WEAPONS_SPEED,
    part: "weapons",
    requirements: {
      firepower: 0,
    },
    grade: "basic",
    derivedStatsModifiers: {},
    isDefault: true,
  },
  default_shields: {
    slug: "default_shields",
    name: "Base Shield",
    description: "Crappy shield that came with your starship.",
    image: baseShieldsImage,
    icon: baseShieldsIcon,
    animation: shieldsAnimation,
    animationSpeed: DEFAULT_SHIELDS_SPEED,
    part: "shields",
    requirements: {
      resilience: 0,
    },
    grade: "basic",
    derivedStatsModifiers: {},
    isDefault: true,
  },
  default_thrusters: {
    slug: "default_thrusters",
    name: "Base Thrusters",
    description: "Crappy thrusters that came with your starship.",
    image: baseThrustersImage,
    icon: baseThrustersIcon,
    animation: thrusterAnimation,
    animationSpeed: DEFAULT_THRUSTERS_SPEED,
    part: "thrusters",
    requirements: {
      speed: 0,
    },
    grade: "basic",
    derivedStatsModifiers: {},
    isDefault: true,
  },
  default_targeting_system: {
    slug: "default_targeting_system",
    name: "Base Targeting Grid",
    description: "Crappy targeting system that came with your starship.",
    image: baseTargetingSystemImage,
    icon: baseTargetingSystemIcon,
    animation: targetingSystemAnimation,
    animationSpeed: DEFAULT_TARGETING_SPEED,
    part: "targetingSystem",
    requirements: {
      precision: 0,
    },
    grade: "basic",
    derivedStatsModifiers: {},
    isDefault: true,
  },
  default_reactor_core: {
    slug: "default_reactor_core",
    name: "Base Reactor Core",
    description: "Crappy reactor core that came with your starship.",
    image: baseReactorCoreImage,
    icon: baseReactorCoreIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 0,
    },
    grade: "basic",
    derivedStatsModifiers: {},
    isDefault: true,
  },
  // CITY SHOP UPGRADES
  bishop_laser_one: {
    slug: "bishop_laser_one",
    name: "Bishop Laser I",
    description: "A standard low performance laser made in Bishop City.",
    image: bishopLaserOneImage,
    icon: bishopLaserOneIcon,
    animation: attackAnimation,
    animationSpeed: DEFAULT_WEAPONS_SPEED,
    part: "weapons",
    requirements: {
      firepower: 1,
    },
    grade: "basic",
    derivedStatsModifiers: {
      maxAttackDamage: 0.5,
      maxWeakenParts: 0.5,
    },
  },
  bishop_laser_two: {
    slug: "bishop_laser_two",
    name: "Bishop Laser II",
    description: "A standard medium performance laser made in Bishop City.",
    image: bishopLaserTwoImage,
    icon: bishopLaserTwoIcon,
    animation: attackAnimation,
    animationSpeed: DEFAULT_WEAPONS_SPEED,
    part: "weapons",
    requirements: {
      firepower: 5,
    },
    grade: "basic",
    derivedStatsModifiers: {
      maxAttackDamage: 0.5,
      maxWeakenParts: 0.5,
    },
  },
  bishop_laser_three: {
    slug: "bishop_laser_three",
    name: "Bishop Laser III",
    description: "A standard high performance laser made in Bishop City.",
    image: bishopLaserThreeImage,
    icon: bishopLaserThreeIcon,
    animation: attackAnimation,
    animationSpeed: DEFAULT_WEAPONS_SPEED,
    part: "weapons",
    requirements: {
      firepower: 10,
    },
    grade: "basic",
    derivedStatsModifiers: {
      maxAttackDamage: 0.5,
      maxWeakenParts: 0.5,
    },
  },
  bishop_laser_four: {
    slug: "bishop_laser_four",
    name: "Bishop Laser IV",
    description: "A standard very high performance laser made in Bishop City.",
    image: bishopLaserFourImage,
    icon: bishopLaserFourIcon,
    animation: attackAnimation,
    animationSpeed: DEFAULT_WEAPONS_SPEED,
    part: "weapons",
    requirements: {
      firepower: 15,
    },
    grade: "basic",
    derivedStatsModifiers: {
      maxAttackDamage: 0.5,
      maxWeakenParts: 0.5,
    },
  },
  bishop_shield_one: {
    slug: "bishop_shield_one",
    name: "Bishop Shield I",
    description: "A standard low performance shield made in Bishop City.",
    image: bishopShieldOneImage,
    icon: bishopShieldOneIcon,
    animation: shieldsAnimation,
    animationSpeed: DEFAULT_SHIELDS_SPEED,
    part: "shields",
    requirements: {
      resilience: 1,
    },
    grade: "basic",
    derivedStatsModifiers: {
      maxHealth: 0.4,
      damageReduction: 0.3,
      weakenPartsReduction: 0.3,
    },
  },
  bishop_shield_two: {
    slug: "bishop_shield_two",
    name: "Bishop Shield II",
    description: "A standard medium performance shield made in Bishop City.",
    image: bishopShieldTwoImage,
    icon: bishopShieldTwoIcon,
    animation: shieldsAnimation,
    animationSpeed: DEFAULT_SHIELDS_SPEED,
    part: "shields",
    requirements: {
      resilience: 5,
    },
    grade: "basic",
    derivedStatsModifiers: {
      maxHealth: 0.4,
      damageReduction: 0.3,
      weakenPartsReduction: 0.3,
    },
  },
  bishop_shield_three: {
    slug: "bishop_shield_three",
    name: "Bishop Shield III",
    description: "A standard high performance shield made in Bishop City.",
    image: bishopShieldThreeImage,
    icon: bishopShieldThreeIcon,
    animation: shieldsAnimation,
    animationSpeed: DEFAULT_SHIELDS_SPEED,
    part: "shields",
    requirements: {
      resilience: 10,
    },
    grade: "basic",
    derivedStatsModifiers: {
      maxHealth: 0.4,
      damageReduction: 0.3,
      weakenPartsReduction: 0.3,
    },
  },
  bishop_shield_four: {
    slug: "bishop_shield_four",
    name: "Bishop Shield IV",
    description: "A standard very high performance shield made in Bishop City.",
    image: bishopShieldFourImage,
    icon: bishopShieldFourIcon,
    animation: shieldsAnimation,
    animationSpeed: DEFAULT_SHIELDS_SPEED,
    part: "shields",
    requirements: {
      resilience: 15,
    },
    grade: "basic",
    derivedStatsModifiers: {
      maxHealth: 0.4,
      damageReduction: 0.3,
      weakenPartsReduction: 0.3,
    },
  },
  bishop_thruster_one: {
    slug: "bishop_thruster_one",
    name: "Bishop Thruster I",
    description: "A standard low performance thruster made in Bishop City.",
    image: bishopThrusterOneImage,
    icon: bishopThrusterOneIcon,
    animation: thrusterAnimation,
    animationSpeed: DEFAULT_THRUSTERS_SPEED,
    part: "thrusters",
    requirements: {
      speed: 1,
    },
    grade: "basic",
    derivedStatsModifiers: {
      attackSpeed: 0.4,
      attackEvasion: 0.3,
      movementSpeed: 0.3,
    },
  },
  bishop_thruster_two: {
    slug: "bishop_thruster_two",
    name: "Bishop Thruster II",
    description: "A standard medium performance thruster made in Bishop City.",
    image: bishopThrusterTwoImage,
    icon: bishopThrusterTwoIcon,
    animation: thrusterAnimation,
    animationSpeed: DEFAULT_THRUSTERS_SPEED,
    part: "thrusters",
    requirements: {
      speed: 5,
    },
    grade: "basic",
    derivedStatsModifiers: {
      attackSpeed: 0.4,
      attackEvasion: 0.3,
      movementSpeed: 0.3,
    },
  },
  bishop_thruster_three: {
    slug: "bishop_thruster_three",
    name: "Bishop Thruster III",
    description: "A standard high performance thruster made in Bishop City.",
    image: bishopThrusterThreeImage,
    icon: bishopThrusterThreeIcon,
    animation: thrusterAnimation,
    animationSpeed: DEFAULT_THRUSTERS_SPEED,
    part: "thrusters",
    requirements: {
      speed: 10,
    },
    grade: "basic",
    derivedStatsModifiers: {
      attackSpeed: 0.4,
      attackEvasion: 0.3,
      movementSpeed: 0.3,
    },
  },
  bishop_thruster_four: {
    slug: "bishop_thruster_four",
    name: "Bishop Thruster IV",
    description:
      "A standard very high performance thruster made in Bishop City.",
    image: bishopThrusterFourImage,
    icon: bishopThrusterFourIcon,
    animation: thrusterAnimation,
    animationSpeed: DEFAULT_THRUSTERS_SPEED,
    part: "thrusters",
    requirements: {
      speed: 15,
    },
    grade: "basic",
    derivedStatsModifiers: {
      attackSpeed: 0.4,
      attackEvasion: 0.3,
      movementSpeed: 0.3,
    },
  },
  bishop_scope_one: {
    slug: "bishop_scope_one",
    name: "Bishop Scope I",
    description:
      "A standard low performance targeting system made in Bishop City.",
    image: bishopScopeOneImage,
    icon: bishopScopeOneIcon,
    animation: targetingSystemAnimation,
    animationSpeed: DEFAULT_TARGETING_SPEED,
    part: "targetingSystem",
    requirements: {
      precision: 1,
    },
    grade: "basic",
    derivedStatsModifiers: {
      attackAccuracy: 0.4,
      minAttackDamage: 0.3,
      minWeakenParts: 0.3,
    },
  },
  bishop_scope_two: {
    slug: "bishop_scope_two",
    name: "Bishop Scope II",
    description:
      "A standard medium performance targeting system made in Bishop City.",
    image: bishopScopeTwoImage,
    icon: bishopScopeTwoIcon,
    animation: targetingSystemAnimation,
    animationSpeed: DEFAULT_TARGETING_SPEED,
    part: "targetingSystem",
    requirements: {
      precision: 5,
    },
    grade: "basic",
    derivedStatsModifiers: {
      attackAccuracy: 0.4,
      minAttackDamage: 0.3,
      minWeakenParts: 0.3,
    },
  },
  bishop_scope_three: {
    slug: "bishop_scope_three",
    name: "Bishop Scope III",
    description:
      "A standard high performance targeting system made in Bishop City.",
    image: bishopScopeThreeImage,
    icon: bishopScopeThreeIcon,
    animation: targetingSystemAnimation,
    animationSpeed: DEFAULT_TARGETING_SPEED,
    part: "targetingSystem",
    requirements: {
      precision: 10,
    },
    grade: "basic",
    derivedStatsModifiers: {
      attackAccuracy: 0.4,
      minAttackDamage: 0.3,
      minWeakenParts: 0.3,
    },
  },
  bishop_scope_four: {
    slug: "bishop_scope_four",
    name: "Bishop Scope IV",
    description:
      "A standard very high performance targeting system made in Bishop City.",
    image: bishopScopeFourImage,
    icon: bishopScopeFourIcon,
    animation: targetingSystemAnimation,
    animationSpeed: DEFAULT_TARGETING_SPEED,
    part: "targetingSystem",
    requirements: {
      precision: 15,
    },
    grade: "basic",
    derivedStatsModifiers: {
      attackAccuracy: 0.4,
      minAttackDamage: 0.3,
      minWeakenParts: 0.3,
    },
  },
  bishop_core_one: {
    slug: "bishop_core_one",
    name: "Bishop Core I",
    description: "A standard low performance reactor core made in Bishop City.",
    image: bishopCoreOneImage,
    icon: bishopCoreOneIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 1,
    },
    grade: "basic",
    derivedStatsModifiers: {
      fasterRecharge: 0.5,
      energyMultiplier: 0.5,
    },
  },
  bishop_core_two: {
    slug: "bishop_core_two",
    name: "Bishop Core II",
    description:
      "A standard medium performance reactor core made in Bishop City.",
    image: bishopCoreTwoImage,
    icon: bishopCoreTwoIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 5,
    },
    grade: "basic",
    derivedStatsModifiers: {
      fasterRecharge: 0.5,
      energyMultiplier: 0.5,
    },
  },
  bishop_core_three: {
    slug: "bishop_core_three",
    name: "Bishop Core III",
    description:
      "A standard high performance reactor core made in Bishop City.",
    image: bishopCoreThreeImage,
    icon: bishopCoreThreeIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 10,
    },
    grade: "basic",
    derivedStatsModifiers: {
      fasterRecharge: 0.5,
      energyMultiplier: 0.5,
    },
  },
  bishop_core_four: {
    slug: "bishop_core_four",
    name: "Bishop Core IV",
    description:
      "A standard very high performance reactor core made in Bishop City.",
    image: bishopCoreFourImage,
    icon: bishopCoreFourIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 15,
    },
    grade: "basic",
    derivedStatsModifiers: {
      fasterRecharge: 0.5,
      energyMultiplier: 0.5,
    },
  },
  seneca_core_one: {
    slug: "seneca_core_one",
    name: "Seneca Core I",
    description: "A standard low performance reactor core made in Vale.",
    image: senecaCoreOneImage,
    icon: senecaCoreOneIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 10,
    },
    grade: "basic",
    derivedStatsModifiers: {
      fasterRecharge: 0.5,
      energyMultiplier: 0.5,
    },
  },
  seneca_core_prime_one: {
    slug: "seneca_core_prime_one",
    name: "Seneca Core Prime I",
    description: "An enhanced low performance reactor core made in Vale.",
    image: senecaCoreOneImage,
    icon: senecaCoreOneIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 10,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      fasterRecharge: 0.5,
      energyMultiplier: 0.5,
    },
  },
  seneca_core_two: {
    slug: "seneca_core_two",
    name: "Seneca Core II",
    description: "A standard medium performance reactor core made in Vale.",
    image: senecaCoreTwoImage,
    icon: senecaCoreTwoIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 15,
    },
    grade: "basic",
    derivedStatsModifiers: {
      fasterRecharge: 0.5,
      energyMultiplier: 0.5,
    },
  },
  seneca_core_prime_two: {
    slug: "seneca_core_prime_two",
    name: "Seneca Core Prime II",
    description: "An enhanced medium performance reactor core made in Vale.",
    image: senecaCoreTwoImage,
    icon: senecaCoreTwoIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 15,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      fasterRecharge: 0.5,
      energyMultiplier: 0.5,
    },
  },
  seneca_core_three: {
    slug: "seneca_core_three",
    name: "Seneca Core III",
    description: "A standard high performance reactor core made in Vale.",
    image: senecaCoreThreeImage,
    icon: senecaCoreThreeIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 20,
    },
    grade: "basic",
    derivedStatsModifiers: {
      fasterRecharge: 0.5,
      energyMultiplier: 0.5,
    },
  },
  seneca_core_prime_three: {
    slug: "seneca_core_prime_three",
    name: "Seneca Core Prime III",
    description: "An enhanced high performance reactor core made in Vale.",
    image: senecaCoreThreeImage,
    icon: senecaCoreThreeIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 20,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      fasterRecharge: 0.5,
      energyMultiplier: 0.5,
    },
  },
  seneca_core_four: {
    slug: "seneca_core_four",
    name: "Seneca Core IV",
    description: "A standard very high performance reactor core made in Vale.",
    image: senecaCoreFourImage,
    icon: senecaCoreFourIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 25,
    },
    grade: "basic",
    derivedStatsModifiers: {
      fasterRecharge: 0.5,
      energyMultiplier: 0.5,
    },
  },
  seneca_core_prime_four: {
    slug: "seneca_core_prime_four",
    name: "Seneca Core Prime IV",
    description: "An enhanced very high performance reactor core made in Vale.",
    image: senecaCoreFourImage,
    icon: senecaCoreFourIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 25,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      fasterRecharge: 0.5,
      energyMultiplier: 0.5,
    },
  },
  // WEAPONS UPGRADES
  glance_laser: {
    slug: "glance_laser",
    name: "Glance Laser",
    description:
      "A small laser upgrade that increases max damage a little bit. Pew pew.",
    image: glanceLaserImage,
    icon: glanceLaserIcon,
    animation: attackAnimation,
    animationSpeed: DEFAULT_WEAPONS_SPEED,
    part: "weapons",
    requirements: {
      firepower: 1,
    },
    grade: "basic",
    derivedStatsModifiers: {
      maxAttackDamage: 1,
    },
  },
  light_laser: {
    slug: "light_laser",
    name: "Light Laser",
    description:
      "A small laser upgrade that increases max damage and max weaken parts a little bit. Pew pew pew.",
    image: lightLaserImage,
    icon: lightLaserIcon,
    animation: attackAnimation,
    animationSpeed: DEFAULT_WEAPONS_SPEED,
    part: "weapons",
    requirements: {
      firepower: 2,
    },
    grade: "basic",
    derivedStatsModifiers: {
      maxAttackDamage: 0.5,
      maxWeakenParts: 0.5,
    },
  },
  pulse_laser: {
    slug: "pulse_laser",
    name: "Pulse Laser",
    description: "A laser upgrade that increases maximum attack damage.",
    image: pulseLaserImage,
    icon: pulseLaserIcon,
    animation: attackAnimation,
    animationSpeed: DEFAULT_WEAPONS_SPEED,
    part: "weapons",
    requirements: {
      firepower: 2,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      maxAttackDamage: 1,
    },
  },
  fade_laser: {
    slug: "fade_laser",
    name: "Fade Laser",
    description: "A laser upgrade that weakens opponent ship parts.",
    image: fadeLaserImage,
    icon: fadeLaserIcon,
    animation: attackAnimation,
    animationSpeed: DEFAULT_WEAPONS_SPEED,
    part: "weapons",
    requirements: {
      firepower: 2,
    },
    grade: "basic",
    derivedStatsModifiers: {
      maxWeakenParts: 1,
    },
  },
  burst_laser: {
    slug: "burst_laser",
    name: "Burst Laser",
    description: "A laser upgrade that increases damage.",
    image: burstLaserImage,
    icon: burstLaserIcon,
    animation: attackAnimation,
    animationSpeed: DEFAULT_WEAPONS_SPEED,
    part: "weapons",
    requirements: {
      firepower: 2,
    },
    grade: "elite",
    derivedStatsModifiers: {
      maxAttackDamage: 1,
    },
  },
  star_beam: {
    slug: "star_beam",
    name: "Star Beam",
    description: "A laser that decently weakens ship parts.",
    image: starBeamImage,
    icon: starBeamIcon,
    animation: attackAnimation,
    animationSpeed: DEFAULT_WEAPONS_SPEED,
    part: "weapons",
    requirements: {
      firepower: 3,
    },
    grade: "basic",
    derivedStatsModifiers: {
      maxWeakenParts: 1,
    },
  },
  heatseeker: {
    slug: "heatseeker",
    name: "Heatseeker",
    description:
      "A laser that inflicts meaningful damage and weakens ship parts.",
    image: heatseekerImage,
    icon: heatseekerIcon,
    animation: attackAnimation,
    animationSpeed: DEFAULT_WEAPONS_SPEED,
    part: "weapons",
    requirements: {
      firepower: 5,
    },
    grade: "elite",
    derivedStatsModifiers: {
      maxAttackDamage: 0.5,
      maxWeakenParts: 0.5,
    },
  },
  firebrand: {
    slug: "firebrand",
    name: "Firebrand",
    description: "A strong laser that inflicts meaningful damage.",
    image: firebrandImage,
    icon: firebrandIcon,
    animation: attackAnimation,
    animationSpeed: DEFAULT_WEAPONS_SPEED,
    part: "weapons",
    requirements: {
      firepower: 6,
    },
    grade: "ultra",
    derivedStatsModifiers: {
      maxAttackDamage: 0.7,
      maxWeakenParts: 0.3,
    },
  },
  moon_shot: {
    slug: "moon_shot",
    name: "Moon Shot",
    description: "A powerful laser that massively weakens ship parts.",
    image: moonShotImage,
    icon: moonShotIcon,
    animation: attackAnimation,
    animationSpeed: DEFAULT_WEAPONS_SPEED,
    part: "weapons",
    requirements: {
      firepower: 9,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      maxWeakenParts: 1,
    },
  },
  star_breaker: {
    slug: "star_breaker",
    name: "Star Breaker",
    description: "Uh... Try not to get hit by this.",
    image: starBreakerImage,
    icon: starBreakerIcon,
    animation: attackAnimation,
    animationSpeed: DEFAULT_WEAPONS_SPEED,
    part: "weapons",
    requirements: {
      firepower: 12,
    },
    grade: "elite",
    derivedStatsModifiers: {
      maxAttackDamage: 1,
    },
  },
  quantum_blade: {
    slug: "quantum_blade",
    name: "Quantum Blade",
    description:
      "Whoa, this thing can boost attack damage AND speed? Is that legal?",
    image: quantumBladeImage,
    icon: quantumBladeIcon,
    animation: attackAnimation,
    animationSpeed: DEFAULT_WEAPONS_SPEED,
    part: "weapons",
    requirements: {
      firepower: 10,
      speed: 6,
    },
    grade: "ultra",
    derivedStatsModifiers: {
      maxAttackDamage: 0.5,
      maxWeakenParts: 0.5,
      attackSpeed: 1,
    },
  },
  energy_beam: {
    slug: "energy_beam",
    name: "Energy Beam",
    description:
      "An antimatter laser that weakens ship parts and increases your pilot skill power.",
    image: energyBeamImage,
    icon: energyBeamIcon,
    animation: attackAnimation,
    animationSpeed: DEFAULT_WEAPONS_SPEED,
    part: "weapons",
    requirements: {
      firepower: 6,
      energy: 3,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      maxWeakenParts: 1,
      energyMultiplier: 1,
    },
  },
  void_cutter: {
    slug: "void_cutter",
    name: "Void Cutter",
    description:
      "A powerful antimatter laser that increases your max damage and also your pilot skill power.",
    image: voidCutterImage,
    icon: voidCutterIcon,
    animation: attackAnimation,
    animationSpeed: DEFAULT_WEAPONS_SPEED,
    part: "weapons",
    requirements: {
      firepower: 8,
      energy: 5,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      maxAttackDamage: 1,
      energyMultiplier: 1,
    },
  },
  null_blade: {
    slug: "null_blade",
    name: "Null Blade",
    description:
      "An antimatter laser that weakens ship parts and increases your max damage and increases your pilot skill power.",
    image: nullBladeImage,
    icon: nullBladeImage,
    animation: attackAnimation,
    animationSpeed: DEFAULT_WEAPONS_SPEED,
    part: "weapons",
    requirements: {
      firepower: 9,
      energy: 6,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      maxAttackDamage: 0.5,
      maxWeakenParts: 0.5,
      fasterRecharge: 1,
    },
  },
  void_blade: {
    slug: "void_blade",
    name: "Void Blade",
    description:
      "A powerful antimatter laser that increases your max damage and also your pilot skill power/speed.",
    image: voidBladeImage,
    icon: voidBladeImage,
    animation: attackAnimation,
    animationSpeed: DEFAULT_WEAPONS_SPEED,
    part: "weapons",
    requirements: {
      firepower: 15,
      energy: 11,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      maxAttackDamage: 1,
      energyMultiplier: 0.5,
      fasterRecharge: 0.5,
    },
  },
  // SHIELDS UPGRADES
  glimmer_shield: {
    slug: "glimmer_shield",
    name: "Glimmer Shield",
    description: "A force field that increases your ship health by a little.",
    image: glimmerShieldImage,
    icon: glimmerShieldIcon,
    animation: shieldsAnimation,
    animationSpeed: DEFAULT_SHIELDS_SPEED,
    part: "shields",
    requirements: {
      resilience: 1,
    },
    grade: "basic",
    derivedStatsModifiers: {
      maxHealth: 1,
    },
  },
  shimmer_shield: {
    slug: "shimmer_shield",
    name: "Shimmer Shield",
    description:
      "A force field that increases your ship health and reduces a bit of damage.",
    image: shimmerShieldImage,
    icon: shimmerShieldIcon,
    animation: shieldsAnimation,
    animationSpeed: DEFAULT_SHIELDS_SPEED,
    part: "shields",
    requirements: {
      resilience: 2,
    },
    grade: "basic",
    derivedStatsModifiers: {
      maxHealth: 0.5,
      damageReduction: 0.5,
    },
  },
  ion_barrier: {
    slug: "ion_barrier",
    name: "Ion Barrier",
    description: "A force field that increases your ship health.",
    image: ionBarrierImage,
    icon: ionBarrierIcon,
    animation: shieldsAnimation,
    animationSpeed: DEFAULT_SHIELDS_SPEED,
    part: "shields",
    requirements: {
      resilience: 2,
    },
    grade: "basic",
    derivedStatsModifiers: {
      maxHealth: 1,
    },
  },
  energy_barrier: {
    slug: "energy_barrier",
    name: "Energy Barrier",
    description: "A force field that increases your ship health.",
    image: energyBarrierImage,
    icon: energyBarrierIcon,
    animation: shieldsAnimation,
    animationSpeed: DEFAULT_SHIELDS_SPEED,
    part: "shields",
    requirements: {
      resilience: 3,
    },
    grade: "basic",
    derivedStatsModifiers: {
      maxHealth: 1,
    },
  },
  protector_shield: {
    slug: "protector_shield",
    name: "Protector Shield",
    description:
      "A decent force field that increases your ship health and damage reduction.",
    image: protectorShieldImage,
    icon: protectorShieldIcon,
    animation: shieldsAnimation,
    animationSpeed: DEFAULT_SHIELDS_SPEED,
    part: "shields",
    requirements: {
      resilience: 4,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      maxHealth: 0.5,
      damageReduction: 0.5,
    },
  },
  bulwark: {
    slug: "bulwark",
    name: "Bulwark",
    description:
      "A force field that deflects damage and protects your ship's parts.",
    image: bulwarkImage,
    icon: bulwarkIcon,
    animation: shieldsAnimation,
    animationSpeed: DEFAULT_SHIELDS_SPEED,
    part: "shields",
    requirements: {
      resilience: 4,
    },
    grade: "basic",
    derivedStatsModifiers: {
      damageReduction: 0.5,
      weakenPartsReduction: 0.5,
    },
  },
  guardian_shield: {
    slug: "guardian_shield",
    name: "Guardian Shield",
    description:
      "A force field that increases your ship health and damage reduction.",
    image: guardianShieldImage,
    icon: guardianShieldIcon,
    animation: shieldsAnimation,
    animationSpeed: 800,
    part: "shields",
    requirements: {
      resilience: 5,
    },
    grade: "elite",
    derivedStatsModifiers: {
      maxHealth: 0.6,
      damageReduction: 0.4,
    },
  },
  vision_wall: {
    slug: "vision_wall",
    name: "Vision Wall",
    description:
      "A special force field that increases your ship health as well as your minimum attack damage.",
    image: visionWallImage,
    icon: visionWallIcon,
    animation: shieldsAnimation,
    animationSpeed: 800,
    part: "shields",
    requirements: {
      resilience: 4,
      precision: 2,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      maxHealth: 1,
      minAttackDamage: 1,
    },
  },
  force_sanctuary: {
    slug: "force_sanctuary",
    name: "Force Sanctuary",
    description:
      "A strong force field that protects your ship parts from damage.",
    image: forceSanctuaryImage,
    icon: forceSanctuaryIcon,
    animation: shieldsAnimation,
    animationSpeed: 600,
    part: "shields",
    requirements: {
      resilience: 7,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      weakenPartsReduction: 1,
    },
  },
  light_sentinel: {
    slug: "light_sentinel",
    name: "Light Sentinel",
    description: "A powerful force field that effortlessly deflects damage.",
    image: lightSentinelImage,
    icon: lightSentinelIcon,
    animation: shieldsAnimation,
    animationSpeed: 600,
    part: "shields",
    requirements: {
      resilience: 9,
    },
    grade: "elite",
    derivedStatsModifiers: {
      damageReduction: 1,
    },
  },
  void_wall: {
    slug: "void_wall",
    name: "Void Wall",
    description:
      "An antimatter force field that deflects damage and recharges your pilot skills faster.",
    image: voidWallImage,
    icon: voidWallIcon,
    animation: shieldsAnimation,
    animationSpeed: 600,
    part: "shields",
    requirements: {
      resilience: 4,
      energy: 3,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      damageReduction: 1,
      fasterRecharge: 1,
    },
  },
  void_guard: {
    slug: "void_guard",
    name: "Void Guard",
    description:
      "A powerful antimatter force field increases your ship's health as well as your pilot skill power.",
    image: voidGuardImage,
    icon: voidGuardIcon,
    animation: shieldsAnimation,
    animationSpeed: 600,
    part: "shields",
    requirements: {
      resilience: 9,
      energy: 6,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      maxHealth: 1,
      energyMultiplier: 1,
    },
  },
  null_shield: {
    slug: "null_shield",
    name: "Null Shield",
    description:
      "An antimatter force field that increases ship health and recharges your pilot skills faster and makes them more powerful.",
    image: nullShieldImage,
    icon: nullShieldImage,
    animation: shieldsAnimation,
    animationSpeed: 2400,
    part: "shields",
    requirements: {
      resilience: 10,
      energy: 6,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      maxHealth: 1,
      energyMultiplier: 0.75,
      fasterRecharge: 0.25,
    },
  },
  null_barrier: {
    slug: "null_barrier",
    name: "Null Barrier",
    description:
      "A powerful antimatter force field increases your ship's health and shields as well as your pilot skill power.",
    image: nullBarrierImage,
    icon: nullBarrierImage,
    animation: shieldsAnimation,
    animationSpeed: 2400,
    part: "shields",
    requirements: {
      resilience: 14,
      energy: 9,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      maxHealth: 0.5,
      damageReduction: 0.5,
      energyMultiplier: 1,
    },
  },
  // THRUSTERS UPGRADES
  spark_booster: {
    slug: "spark_booster",
    name: "Spark Booster",
    description:
      "Small thrusters that increase your attack frequency and ability to take lower damage hits by a little bit.",
    image: sparkBoosterImage,
    icon: sparkBoosterIcon,
    animation: thrusterAnimation,
    animationSpeed: DEFAULT_THRUSTERS_SPEED,
    part: "thrusters",
    requirements: {
      speed: 1,
    },
    grade: "basic",
    derivedStatsModifiers: {
      attackSpeed: 0.5,
      attackEvasion: 0.5,
    },
  },
  light_thruster: {
    slug: "light_thruster",
    name: "Light Thruster",
    description:
      "Thrusters that increase your attack frequency and ability to take lower damage hits by a bit.",
    image: lightThrusterImage,
    icon: lightThrusterIcon,
    animation: thrusterAnimation,
    animationSpeed: DEFAULT_THRUSTERS_SPEED,
    part: "thrusters",
    requirements: {
      speed: 2,
    },
    grade: "basic",
    derivedStatsModifiers: {
      attackSpeed: 0.5,
      attackEvasion: 0.5,
    },
  },
  flash_rocket: {
    slug: "flash_rocket",
    name: "Flash Rocket",
    description:
      "Thrusters that increase your ability to attack more often and avoid attacks.",
    image: flashRocketImage,
    icon: flashRocketIcon,
    animation: thrusterAnimation,
    animationSpeed: DEFAULT_THRUSTERS_SPEED,
    part: "thrusters",
    requirements: {
      speed: 3,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      attackSpeed: 0.5,
      attackEvasion: 0.5,
    },
  },
  jetsetter: {
    slug: "jetsetter",
    name: "Jetsetter",
    description:
      "Thrusters that increase your ability to deal attacks more often.",
    image: jetsetterImage,
    icon: jetsetterIcon,
    animation: thrusterAnimation,
    animationSpeed: DEFAULT_THRUSTERS_SPEED,
    part: "thrusters",
    requirements: {
      speed: 4,
    },
    grade: "basic",
    derivedStatsModifiers: {
      attackSpeed: 1,
    },
  },
  kinetica: {
    slug: "kinetica",
    name: "Kinetica",
    description:
      "Thrusters that increase your ability to attack more often, and avoid highest damage.",
    image: kineticaImage,
    icon: kineticaIcon,
    animation: thrusterAnimation,
    animationSpeed: DEFAULT_THRUSTERS_SPEED,
    part: "thrusters",
    requirements: {
      speed: 4,
    },
    grade: "elite",
    derivedStatsModifiers: {
      attackSpeed: 0.75,
      attackEvasion: 0.25,
    },
  },
  hyperburner: {
    slug: "hyperburner",
    name: "Hyperburner",
    description:
      "Strong thrusters that allow you to parry high damage attacks.",
    image: hyperburnerImage,
    icon: hyperburnerIcon,
    animation: thrusterAnimation,
    animationSpeed: DEFAULT_THRUSTERS_SPEED,
    part: "thrusters",
    requirements: {
      speed: 6,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      attackEvasion: 1,
    },
  },
  novaburner: {
    slug: "novaburner",
    name: "Novaburner",
    description:
      "Strong thrusters that increase the frequency of your attacks.",
    image: novaburnerImage,
    icon: novaburnerIcon,
    animation: thrusterAnimation,
    animationSpeed: DEFAULT_THRUSTERS_SPEED,
    part: "thrusters",
    requirements: {
      speed: 8,
    },
    grade: "elite",
    derivedStatsModifiers: {
      attackSpeed: 1,
    },
  },
  void_drive: {
    slug: "void_drive",
    name: "Void Drive",
    description:
      "Powerful thrusters that elevate the frequency of your attacks as well as your shielding ...somehow.",
    image: voidDriveImage,
    icon: voidDriveIcon,
    animation: thrusterAnimation,
    animationSpeed: DEFAULT_THRUSTERS_SPEED,
    part: "thrusters",
    requirements: {
      speed: 6,
      resilience: 4,
    },
    grade: "elite",
    derivedStatsModifiers: {
      attackSpeed: 1,
      maxHealth: 0.5,
      damageReduction: 0.5,
    },
  },
  nexus_rocket: {
    slug: "nexus_rocket",
    name: "Nexus Rocket",
    description:
      "Special energy thrusters that elevate your ability to avoid attacks and increases your pilot skill power.",
    image: nexusRocketImage,
    icon: nexusRocketIcon,
    animation: thrusterAnimation,
    animationSpeed: DEFAULT_THRUSTERS_SPEED,
    part: "thrusters",
    requirements: {
      speed: 6,
      energy: 4,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      attackEvasion: 1,
      energyMultiplier: 1,
    },
  },
  voidburner: {
    slug: "voidburner",
    name: "Voidburner",
    description:
      "Powerful energy thrusters that elevate the frequency of your attacks as well as recharges your pilot skills faster. Speed on speed.",
    image: voidburnerImage,
    icon: voidburnerIcon,
    animation: thrusterAnimation,
    animationSpeed: DEFAULT_THRUSTERS_SPEED,
    part: "thrusters",
    requirements: {
      speed: 9,
      energy: 5,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      attackSpeed: 1,
      fasterRecharge: 1,
    },
  },
  plasma_drive: {
    slug: "plasma_drive",
    name: "Plasma Drive",
    description:
      "Special energy thrusters that elevate your ability to attack faster, avoid attacks, and increases your pilot skill power.",
    image: plasmaDriveImage,
    icon: plasmaDriveIcon,
    animation: thrusterAnimation,
    animationSpeed: DEFAULT_THRUSTERS_SPEED,
    part: "thrusters",
    requirements: {
      speed: 10,
      energy: 7,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      attackSpeed: 0.5,
      attackEvasion: 0.5,
      fasterRecharge: 1,
    },
  },
  nova_drive: {
    slug: "nova_drive",
    name: "Nova Drive",
    description:
      "Powerful energy thrusters that elevate the frequency of your attacks as well as recharges your pilot skills faster. Speed on speed.",
    image: novaDriveImage,
    icon: novaDriveIcon,
    animation: thrusterAnimation,
    animationSpeed: DEFAULT_THRUSTERS_SPEED,
    part: "thrusters",
    requirements: {
      speed: 12,
      energy: 8,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      attackSpeed: 1,
      fasterRecharge: 1,
    },
  },
  // TARGETING SYSTEM UPGRADES
  dark_scope: {
    slug: "dark_scope",
    name: "Dark Scope",
    description:
      "A small targeting system upgrade that slightly increases your minimum damage.",
    image: darkScopeImage,
    icon: darkScopeIcon,
    animation: targetingSystemAnimation,
    animationSpeed: DEFAULT_TARGETING_SPEED,
    part: "targetingSystem",
    requirements: {
      precision: 1,
    },
    grade: "basic",
    derivedStatsModifiers: {
      minAttackDamage: 1,
    },
  },
  small_scope: {
    slug: "small_scope",
    name: "Small Scope",
    description:
      "A small targeting system upgrade that slightly increases your minimum damage and hit chance.",
    image: smallScopeImage,
    icon: smallScopeIcon,
    animation: targetingSystemAnimation,
    animationSpeed: DEFAULT_TARGETING_SPEED,
    part: "targetingSystem",
    requirements: {
      precision: 2,
    },
    grade: "basic",
    derivedStatsModifiers: {
      attackAccuracy: 0.5,
      minAttackDamage: 0.5,
    },
  },
  vision_scope: {
    slug: "vision_scope",
    name: "Vision Scope",
    description:
      "A targeting system upgrade that increases your minimum damage and hit chance.",
    image: visionScopeImage,
    icon: visionScopeIcon,
    animation: targetingSystemAnimation,
    animationSpeed: DEFAULT_TARGETING_SPEED,
    part: "targetingSystem",
    requirements: {
      precision: 2,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      attackAccuracy: 0.5,
      minAttackDamage: 0.5,
    },
  },
  lock_sight: {
    slug: "lock_sight",
    name: "Lock Sight",
    description:
      "A targeting system upgrade that increases your minimum damage.",
    image: lockSightImage,
    icon: lockSightIcon,
    animation: targetingSystemAnimation,
    animationSpeed: DEFAULT_TARGETING_SPEED,
    part: "targetingSystem",
    requirements: {
      precision: 3,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      minAttackDamage: 1,
    },
  },
  fader_grid: {
    slug: "fader_grid",
    name: "Fader Grid",
    description:
      "A targeting system upgrade that increases your minimum attack damage.",
    image: faderGridImage,
    icon: faderGridIcon,
    animation: targetingSystemAnimation,
    animationSpeed: DEFAULT_TARGETING_SPEED,
    part: "targetingSystem",
    requirements: {
      precision: 3,
    },
    grade: "basic",
    derivedStatsModifiers: {
      minAttackDamage: 1,
    },
  },
  sharp_scope: {
    slug: "sharp_scope",
    name: "Sharp Scope",
    description:
      "A targeting system upgrade that increases the chance of consistently landing hits.",
    image: sharpScopeImage,
    icon: sharpScopeIcon,
    animation: targetingSystemAnimation,
    animationSpeed: DEFAULT_TARGETING_SPEED,
    part: "targetingSystem",
    requirements: {
      precision: 3,
    },
    grade: "elite",
    derivedStatsModifiers: {
      attackAccuracy: 0.8,
      minAttackDamage: 0.2,
    },
  },
  photon_sight: {
    slug: "photon_sight",
    name: "Photon Sight",
    description:
      "A special targeting system that increases your minimum attack damage as well as bumps up your antimatter boost.",
    image: photonSightImage,
    icon: photonSightIcon,
    animation: targetingSystemAnimation,
    animationSpeed: DEFAULT_TARGETING_SPEED,
    part: "targetingSystem",
    requirements: {
      precision: 2,
      energy: 1,
    },
    grade: "basic",
    derivedStatsModifiers: {
      minAttackDamage: 1,
      energyMultiplier: 1,
    },
  },
  needle_grid: {
    slug: "needle_grid",
    name: "Needle Grid",
    description:
      "A robust targeting system upgrade increase your minimum attack damage.",
    image: needleGridImage,
    icon: needleGridIcon,
    animation: targetingSystemAnimation,
    animationSpeed: DEFAULT_TARGETING_SPEED,
    part: "targetingSystem",
    requirements: {
      precision: 7,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      minAttackDamage: 1,
    },
  },
  stellar_sight: {
    slug: "stellar_sight",
    name: "Stellar Sight",
    description:
      "A powerful targeting system upgrade that helps you land attacks more consistently.",
    image: stellarSightImage,
    icon: stellarSightIcon,
    animation: targetingSystemAnimation,
    animationSpeed: DEFAULT_TARGETING_SPEED,
    part: "targetingSystem",
    requirements: {
      precision: 10,
    },
    grade: "elite",
    derivedStatsModifiers: {
      attackAccuracy: 1,
    },
  },
  energy_scope: {
    slug: "energy_scope",
    name: "Energy Scope",
    description:
      "An antimatter-based targeting system upgrade that increases your minimum damage and recharges your skills faster.",
    image: energyScopeImage,
    icon: energyScopeIcon,
    animation: targetingSystemAnimation,
    animationSpeed: DEFAULT_TARGETING_SPEED,
    part: "targetingSystem",
    requirements: {
      precision: 5,
      energy: 3,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      minAttackDamage: 1,
      fasterRecharge: 1,
    },
  },
  void_scope: {
    slug: "void_scope",
    name: "Void Scope",
    description:
      "An antimatter-based targeting system upgrade that helps you land attacks more consistently and increases your pilot skill power.",
    image: voidScopeImage,
    icon: voidScopeIcon,
    animation: targetingSystemAnimation,
    animationSpeed: DEFAULT_TARGETING_SPEED,
    part: "targetingSystem",
    requirements: {
      precision: 7,
      energy: 4,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      attackAccuracy: 1,
      energyMultiplier: 1,
    },
  },
  flux_sight: {
    slug: "flux_sight",
    name: "Flux Sight",
    description:
      "An antimatter-based targeting system upgrade that increases your minimum damage and recharges your skills faster.",
    image: energyScopeImage,
    icon: energyScopeIcon,
    animation: targetingSystemAnimation,
    animationSpeed: DEFAULT_TARGETING_SPEED,
    part: "targetingSystem",
    requirements: {
      precision: 5,
      energy: 3,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      minAttackDamage: 1,
      fasterRecharge: 1,
    },
  },
  flux_scope: {
    slug: "flux_scope",
    name: "Flux Scope",
    description:
      "An antimatter-based targeting system upgrade that helps you land attacks more consistently and increases your pilot skill power.",
    image: fluxScopeImage,
    icon: fluxScopeIcon,
    animation: targetingSystemAnimation,
    animationSpeed: DEFAULT_TARGETING_SPEED,
    part: "targetingSystem",
    requirements: {
      precision: 8,
      energy: 5,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      attackAccuracy: 1,
      energyMultiplier: 1,
    },
  },
  phaser_sight: {
    slug: "phaser_sight",
    name: "Phaser Sight",
    description:
      "An antimatter-based targeting system upgrade that helps you land attacks more consistently and increases your pilot skill power.",
    image: phaserSightImage,
    icon: phaserSightIcon,
    animation: targetingSystemAnimation,
    animationSpeed: DEFAULT_TARGETING_SPEED,
    part: "targetingSystem",
    requirements: {
      precision: 15,
      energy: 10,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      attackAccuracy: 1,
      energyMultiplier: 1,
    },
  },
  // REACTOR CORE UPGRADES
  zero_core: {
    slug: "zero_core",
    name: "Zero Core",
    description:
      "A small upgrade to your reactor core that allows for slightly higher efficacy of pilot abilities.",
    image: zeroCoreImage,
    icon: zeroCoreIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 1,
    },
    grade: "basic",
    derivedStatsModifiers: {
      energyMultiplier: 1,
    },
  },
  quick_core: {
    slug: "quick_core",
    name: "Quick Core",
    description:
      "A small upgrade to your reactor core that allows for slightly more frequent use and higher efficacy of pilot abilities.",
    image: quickCoreImage,
    icon: quickCoreIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 2,
    },
    grade: "basic",
    derivedStatsModifiers: {
      energyMultiplier: 0.5,
      fasterRecharge: 0.5,
    },
  },
  wave_cell: {
    slug: "wave_cell",
    name: "Wave Cell",
    description:
      "An upgrade to your reactor core that strengthens your pilot abilities and allows your abilities to recharge quicker.",
    image: waveCellImage,
    icon: waveCellIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 2,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      energyMultiplier: 0.5,
      fasterRecharge: 0.5,
    },
  },
  storm_cell: {
    slug: "storm_cell",
    name: "Storm Cell",
    description:
      "A robust upgrade to your reactor core that strengthens your pilot abilities.",
    image: stormCellImage,
    icon: stormCellIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 3,
    },
    grade: "elite",
    derivedStatsModifiers: {
      energyMultiplier: 1,
    },
  },
  glow_reactor: {
    slug: "glow_reactor",
    name: "Glow Reactor",
    description:
      "An upgrade to your reactor core that strengthens your pilot abilities and skill frequency.",
    image: glowReactorImage,
    icon: glowReactorIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 5,
    },
    grade: "basic",
    derivedStatsModifiers: {
      energyMultiplier: 0.5,
      fasterRecharge: 0.5,
    },
  },
  velo_nexus: {
    slug: "velo_nexus",
    name: "Velo Nexus",
    description:
      "A powerful upgrade to your reactor core that allows you to employ pilot abilities more often.",
    image: veloNexusImage,
    icon: veloNexusIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 5,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      fasterRecharge: 1,
    },
  },
  aether_nexus: {
    slug: "aether_nexus",
    name: "Aether Nexus",
    description:
      "A very powerful upgrade to your reactor core that boosts your pilot abilities handily.",
    image: aetherNexusImage,
    icon: aetherNexusIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 7,
    },
    grade: "elite",
    derivedStatsModifiers: {
      energyMultiplier: 1,
    },
  },
  vapor_core: {
    slug: "vapor_core",
    name: "Vapor Core",
    description:
      "An upgrade to your reactor core that boosts your skill recharging speed and power.",
    image: vaporCoreImage,
    icon: vaporCoreIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 8,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      energyMultiplier: 0.75,
      fasterRecharge: 0.25,
    },
  },
  zephyr_core: {
    slug: "zephyr_core",
    name: "Zephyr Core",
    description:
      "An solid upgrade to your reactor core that boosts your skill recharging speed and power.",
    image: zephyrCoreImage,
    icon: zephyrCoreIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 10,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      energyMultiplier: 0.25,
      fasterRecharge: 0.75,
    },
  },
  mega_reactor: {
    slug: "mega_reactor",
    name: "Mega Reactor",
    description:
      "A decently powerful upgrade to your reactor core that boosts your skill recharging speed and power.",
    image: megaReactorImage,
    icon: megaReactorIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 12,
    },
    grade: "elite",
    derivedStatsModifiers: {
      energyMultiplier: 0.5,
      fasterRecharge: 0.5,
    },
  },
  hyper_reactor: {
    slug: "hyper_reactor",
    name: "Hyper Reactor",
    description:
      "A quite powerful upgrade to your reactor core that really boosts your skill power.",
    image: hyperReactorImage,
    icon: hyperReactorIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 15,
    },
    grade: "ultra",
    derivedStatsModifiers: {
      energyMultiplier: 1,
    },
  },
  sharp_core: {
    slug: "sharp_core",
    name: "Sharp Core",
    description:
      "A powerful upgrade to your reactor core that boosts your skill power and min attack damage.",
    image: sharpCoreImage,
    icon: sharpCoreIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 10,
      precision: 4,
    },
    grade: "elite",
    derivedStatsModifiers: {
      energyMultiplier: 1,
      minAttackDamage: 1,
    },
  },
  lock_core: {
    slug: "lock_core",
    name: "Lock Core",
    description:
      "A powerful upgrade to your reactor core that boosts your skill recharging speed and attack accuracy.",
    image: lockCoreImage,
    icon: lockCoreIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 13,
      precision: 7,
    },
    grade: "ultra",
    derivedStatsModifiers: {
      fasterRecharge: 1,
      attackAccuracy: 1,
    },
  },
  force_nexus: {
    slug: "force_nexus",
    name: "Force Nexus",
    description:
      "A powerful upgrade to your reactor core that boosts your skill power and ability to weaken opponent parts.",
    image: forceNexusImage,
    icon: forceNexusIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 12,
      firepower: 5,
    },
    grade: "elite",
    derivedStatsModifiers: {
      energyMultiplier: 1,
      maxWeakenParts: 1,
    },
  },
  striker_nexus: {
    slug: "striker_nexus",
    name: "Striker Nexus",
    description:
      "A powerful upgrade to your reactor core that boosts your skill recharging speed and max attack damage.",
    image: strikerNexusImage,
    icon: strikerNexusIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 15,
      firepower: 8,
    },
    grade: "ultra",
    derivedStatsModifiers: {
      fasterRecharge: 1,
      maxAttackDamage: 1,
    },
  },
  swift_nexus: {
    slug: "swift_nexus",
    name: "Swift Nexus",
    description:
      "A powerful upgrade to your reactor core that boosts your skill power and evasion speed.",
    image: swiftNexusImage,
    icon: swiftNexusImage,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 13,
      speed: 6,
    },
    grade: "elite",
    derivedStatsModifiers: {
      energyMultiplier: 1,
      attackEvasion: 1,
    },
  },
  flash_nexus: {
    slug: "flash_nexus",
    name: "Flash Nexus",
    description:
      "A powerful upgrade to your reactor core that boosts your skill recharging speed and attack speed.",
    image: flashNexusImage,
    icon: flashNexusImage,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 16,
      speed: 9,
    },
    grade: "ultra",
    derivedStatsModifiers: {
      fasterRecharge: 1,
      attackSpeed: 1,
    },
  },
  castle_core: {
    slug: "castle_core",
    name: "Castle Core",
    description:
      "A powerful upgrade to your reactor core that boosts your pilot skill power and ship parts protection.",
    image: castleCoreImage,
    icon: castleCoreImage,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 14,
      resilience: 6,
    },
    grade: "elite",
    derivedStatsModifiers: {
      energyMultiplier: 1,
      weakenPartsReduction: 1,
    },
  },
  fortress_core: {
    slug: "fortress_core",
    name: "Fortress Core",
    description:
      "An extremely powerful upgrade to your reactor core that boosts your skill recharging speed and also shields somehow.",
    image: fortressCoreImage,
    icon: fortressCoreImage,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 17,
      resilience: 9,
    },
    grade: "ultra",
    derivedStatsModifiers: {
      fasterRecharge: 1,
      damageReduction: 1,
    },
  },
  moon_nexus: {
    slug: "moon_nexus",
    name: "Moon Nexus",
    description:
      "A powerful upgrade to your reactor core that boosts the speed and power of your abilities.",
    image: moonNexusImage,
    icon: moonNexusImage,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 14,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      energyMultiplier: 0.25,
      fasterRecharge: 0.75,
    },
  },
  star_nexus: {
    slug: "star_nexus",
    name: "Star Nexus",
    description:
      "A very powerful upgrade to your reactor core that boosts the power and speed of your abilities.",
    image: starNexusImage,
    icon: starNexusImage,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 16,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      energyMultiplier: 0.75,
      fasterRecharge: 0.25,
    },
  },
  supernova: {
    slug: "supernova",
    name: "Supernova",
    description:
      "Install this core at your own risk. Or really, the risk of your opponents.",
    image: supernovaImage,
    icon: supernovaImage,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 20,
    },
    grade: "elite",
    derivedStatsModifiers: {
      energyMultiplier: 0.5,
      fasterRecharge: 0.5,
    },
  },
  hypernova: {
    slug: "hypernova",
    name: "H Y P E R N O V A",
    description: "This thing looks intense. I'd be careful if I were you.",
    image: hypernovaImage,
    icon: hypernovaImage,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 26,
    },
    grade: "ultra",
    derivedStatsModifiers: {
      energyMultiplier: 1,
    },
  },
  surge_core: {
    slug: "surge_core",
    name: "Surge Core",
    description:
      "An upgrade to your reactor core that boosts your skill recharging speed and power.",
    image: surgeCoreImage,
    icon: surgeCoreImage,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 15,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      energyMultiplier: 0.5,
      fasterRecharge: 0.5,
    },
  },
  mega_core: {
    slug: "mega_core",
    name: "Mega Core",
    description:
      "An solid upgrade to your reactor core that boosts your skill recharging power and speed.",
    image: megaCoreImage,
    icon: megaCoreImage,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 18,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      energyMultiplier: 0.75,
      fasterRecharge: 0.25,
    },
  },
  alpha_reactor: {
    slug: "alpha_reactor",
    name: "Alpha Reactor",
    description:
      "A decently powerful upgrade to your reactor core that boosts your skill recharging speed and power.",
    image: alphaReactorImage,
    icon: alphaReactorImage,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 23,
    },
    grade: "elite",
    derivedStatsModifiers: {
      energyMultiplier: 0.5,
      fasterRecharge: 0.5,
    },
  },
  fury_reactor: {
    slug: "fury_reactor",
    name: "Fury Reactor",
    description:
      "A quite powerful upgrade to your reactor core that really boosts your skill power.",
    image: furyReactorImage,
    icon: furyReactorImage,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 29,
    },
    grade: "ultra",
    derivedStatsModifiers: {
      energyMultiplier: 1,
    },
  },
  dash_matrix: {
    slug: "dash_matrix",
    name: "Dash Matrix",
    description:
      "A powerful upgrade to your reactor core that boosts your skill power and attack/evasion speed.",
    image: dashMatrixImage,
    icon: dashMatrixImage,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 19,
      speed: 9,
    },
    grade: "elite",
    derivedStatsModifiers: {
      energyMultiplier: 1,
      attackSpeed: 0.5,
      attackEvasion: 0.5,
    },
  },
  burst_matrix: {
    slug: "burst_matrix",
    name: "Burst Matrix",
    description:
      "A powerful upgrade to your reactor core that boosts your skill recharging speed/power and attack speed.",
    image: burstMatrixImage,
    icon: burstMatrixImage,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 24,
      speed: 12,
    },
    grade: "ultra",
    derivedStatsModifiers: {
      energyMultiplier: 0.5,
      fasterRecharge: 0.5,
      attackSpeed: 1,
    },
  },
  ward_nexus: {
    slug: "ward_nexus",
    name: "Ward Nexus",
    description:
      "A powerful upgrade to your reactor core that boosts your pilot skill power and ship parts protection.",
    image: wardNexusImage,
    icon: wardNexusImage,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 21,
      resilience: 10,
    },
    grade: "elite",
    derivedStatsModifiers: {
      energyMultiplier: 1,
      weakenPartsReduction: 1,
    },
  },
  aegis_core: {
    slug: "aegis_core",
    name: "Aegis Core",
    description:
      "An extremely powerful upgrade to your reactor core that boosts your skill recharging speed and also deflects damage.",
    image: aegisCoreImage,
    icon: aegisCoreImage,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 26,
      resilience: 14,
    },
    grade: "ultra",
    derivedStatsModifiers: {
      fasterRecharge: 1,
      damageReduction: 1,
    },
  },
  sight_matrix: {
    slug: "sight_matrix",
    name: "Sight Matrix",
    description:
      "A powerful upgrade to your reactor core that boosts your skill recharging speed/power and minimum damage.",
    image: sightMatrixImage,
    icon: sightMatrixImage,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 24,
      precision: 11,
    },
    grade: "elite",
    derivedStatsModifiers: {
      energyMultiplier: 0.5,
      fasterRecharge: 0.5,
      minAttackDamage: 1,
    },
  },
  tracer_matrix: {
    slug: "tracer_matrix",
    name: "Tracer Matrix",
    description:
      "A powerful upgrade to your reactor core that boosts your skill power and attack accuracy and minimum damage.",
    image: tracerMatrixImage,
    icon: tracerMatrixImage,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 29,
      precision: 15,
    },
    grade: "ultra",
    derivedStatsModifiers: {
      energyMultiplier: 1,
      attackAccuracy: 0.5,
      minAttackDamage: 0.5,
    },
  },
  reaper_core: {
    slug: "reaper_core",
    name: "Reaper Core",
    description:
      "A powerful upgrade to your reactor core that boosts your skill power/speed and ability to weaken opponent parts.",
    image: reaperCoreImage,
    icon: reaperCoreImage,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 25,
      firepower: 12,
    },
    grade: "elite",
    derivedStatsModifiers: {
      energyMultiplier: 0.5,
      fasterRecharge: 0.5,
      maxWeakenParts: 1,
    },
  },
  ravager_core: {
    slug: "ravager_core",
    name: "Ravager Core",
    description:
      "A very powerful upgrade to your reactor core that boosts your skill power and max attack damage.",
    image: ravagerCoreImage,
    icon: ravagerCoreImage,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 30,
      firepower: 15,
    },
    grade: "ultra",
    derivedStatsModifiers: {
      energyMultiplier: 1,
      maxAttackDamage: 1,
    },
  },
  nebula_core: {
    slug: "nebula_core",
    name: "Nebula Core",
    description:
      "A quite powerful upgrade to your reactor core that boosts the speed and power of your abilities.",
    image: nebulaCoreImage,
    icon: nebulaCoreImage,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 24,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      energyMultiplier: 0.25,
      fasterRecharge: 0.75,
    },
  },
  eclipse_core: {
    slug: "eclipse_core",
    name: "Eclipse Core",
    description:
      "An immensely powerful upgrade to your reactor core that boosts the power and speed of your abilities.",
    image: eclipseCoreImage,
    icon: eclipseCoreImage,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 28,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      energyMultiplier: 0.75,
      fasterRecharge: 0.25,
    },
  },
  apex_core: {
    slug: "apex_core",
    name: "Apex Core",
    description:
      "I would wear gloves when installing this core. It's a bit volatile.",
    image: apexCoreImage,
    icon: apexCoreImage,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 34,
    },
    grade: "elite",
    derivedStatsModifiers: {
      energyMultiplier: 0.5,
      fasterRecharge: 0.5,
    },
  },
  omega_core: {
    slug: "omega_core",
    name: "O M E G A Ω C O R E",
    description:
      "Wow this core is special. So special it has a symbol in its name.",
    image: omegaCoreImage,
    icon: omegaCoreImage,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirements: {
      energy: 42,
    },
    grade: "ultra",
    derivedStatsModifiers: {
      energyMultiplier: 1,
    },
  },
};

interface GradeData {
  [key: string]: GradeModel;
}

export const GRADES: GradeData = {
  basic: {
    slug: "basic",
    name: "Basic",
    color: "#777777",
    multiplier: 1,
  },
  advanced: {
    slug: "advanced",
    name: "Advanced",
    color: "#0c5ff2",
    multiplier: 1.1,
  },
  elite: {
    slug: "elite",
    name: "Elite",
    color: "#dc2eef",
    multiplier: 1.25,
  },
  ultra: {
    slug: "ultra",
    name: "Ultra",
    color: "#facd05",
    multiplier: 1.5,
  },
};
